<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" md="6">
        <v-card class="fill-height">
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>Welcome Heather</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            {{welcome}}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>Welcome Heather - Thank you Jon!</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div>This uses computed</div>
            <v-text-field
              label="A"
              v-model="a"
              type="number"
              hint="Enter a number"
              persistent-hint
              color="color3"
            ></v-text-field>
            <div class="text-center py-3">
              <v-icon color="color3">fas fa-plus</v-icon>
            </div>
            <v-text-field
              label="B"
              v-model="b"
              type="number"
              hint="Enter a number"
              persistent-hint
              color="color3"
            ></v-text-field>
            <div class="text-center py-3">
              <v-icon color="color3">fas fa-equals</v-icon>
            </div>
            <h1 class="text-center py-3">
              {{total}}
            </h1>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      welcome: 'This is pulled from data',
      a: 0,
      b: 0
    }
  },
  computed: {
    total () {
      return (+this.a) + (+this.b)
    }
  }
}
</script>

<style scoped>

</style>
